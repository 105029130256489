import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TinyMCEWrapper from 'components/tinyMCEWrapper';
import styles from './About.scss';

export default class About extends Component {
  state = {
    editing: false,
    collapsed: this.props.showLess,
  };

  onAboutChange = e => {
    this.setState({
      text: e.target.getContent(),
    });
  };

  onAboutSave = () => {
    const { updateAbout, id } = this.props;
    updateAbout(id, this.state.text);
    this.toggleEdit();
  };

  toggleEdit = () => {
    this.setState(prevState => ({
      editing: !prevState.editing,
    }));
  };

  toggleShow = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  render() {
    const { allowEdit, text, showLess } = this.props;
    const { editing, collapsed } = this.state;

    if (!text && !allowEdit) return null;

    return (
      <div id="about" className={classNames('mb-20', collapsed ? styles.collapsed : styles.expanded)}>
        <h6 className="is-size-5-5 mb-10 has-text-weight-semibold has-text-grey-dark is-uppercase">
          <Trans>ABOUT</Trans>{' '}
          {allowEdit &&
            !editing && (
              <span role="button" className="ml-10" tabIndex={0} onClick={this.toggleEdit}>
                <img src="/images/pencil.png" alt="pencil" />
              </span>
            )}
        </h6>

        {!editing ? (
          // eslint-disable-next-line react/no-danger
          <div className={styles.content} dangerouslySetInnerHTML={{ __html: text }} itemProp="description" />
        ) : (
          <TinyMCEWrapper content={text} onChange={this.onAboutChange} onSave={this.onAboutSave} />
        )}
        {showLess &&
          !editing && (
            <button onClick={this.toggleShow} className="button button-link has-text-link pl-0">
              <span className="icon is-small">
                <i className={`fa fa-angle-${collapsed ? 'down' : 'up'}`} />
              </span>
              <span>{collapsed ? <Trans>Show more</Trans> : <Trans>Show less</Trans>}</span>
            </button>
          )}
      </div>
    );
  }
}

About.defaultProps = {
  showLess: true,
  text: '',
};

About.propTypes = {
  updateAbout: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  allowEdit: PropTypes.bool.isRequired,
  text: PropTypes.string,
  showLess: PropTypes.bool,
};
