/* global window, document */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Scroll from 'react-scroll';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import { modalActions } from 'modules/modal/actions';
import SpecialistPicture from 'components/specialist/specialistPicture';
import SearchLink from 'components/link/SearchLink';
import StarRating from 'components/starRating';

import { actions } from 'modules/specialist/actions';
import styles from './SpecialistHeader.scss';

const SpecialistHeader = ({ specialist, allowEdit, dispatch }) => (
  <div className="container">
    <div className="columns">
      <div className="column is-7">
        <div className={classNames('mr-30', styles.avatar)}>
          <div className={styles.avatarImage}>
            <SpecialistPicture specialistId={specialist.id} profilePicture={specialist.profilePicture} />
            {allowEdit && (
              <div
                className={styles.btnUploadPicture}
                role="button"
                tabIndex={0}
                onClick={() =>
                  dispatch(
                    modalActions.showPictureUploadModal(
                      specialist.id,
                      specialist.profilePicture
                        ? `/images/profile/Id${specialist.id}-ProfilPic.jpg`
                        : '/images/profile/default/default-profile-picture.png',
                      actions.updateProfilePicture,
                      true
                    )
                  )
                }
              >
                <i className="fa fa-cloud-upload" />
              </div>
            )}
          </div>
        </div>

        <div className={styles.rtc}>
          <h1 className="is-size-3 has-text-weight-semibold has-text-primary" itemProp="name">
            {`${specialist.firstName} ${specialist.lastName}`}
          </h1>
          <h2 className="is-size-6 mb-5 has-text-weight-semibold">
            <SearchLink
              town={specialist.practices && specialist.practices[0].town}
              speciality={specialist.specialization}
              className="has-text-grey-dark"
            >
              <span itemProp="medicalSpecialty">{specialist.specialization}</span>
            </SearchLink>
          </h2>
          <div className="bottom" itemProp="aggregateRating" itemScope itemType="http://schema.org/AggregateRating">
            {specialist.rating > 0 && <meta itemProp="ratingValue" content={specialist.rating} />}

            <StarRating size={21} name="currentRating" value={specialist.rating} />

            <Scroll.Link
              className={classNames('button button-link has-text-link is-small', styles.reviewLink)}
              to="review_section"
              smooth
              duration={500}
            >
              {specialist.reviews.length === 0 ? (
                <Trans>Write a Review</Trans>
              ) : (
                <Trans>
                  Read patient reviews (<span itemProp="ratingCount">{specialist.reviews.length}</span>)
                </Trans>
              )}
            </Scroll.Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

SpecialistHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  specialist: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    specialization: PropTypes.string,
    rating: PropTypes.number,
  }).isRequired,
  allowEdit: PropTypes.bool.isRequired,
};

export default connect()(SpecialistHeader);
