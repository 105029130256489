// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import { DateFormat } from '@lingui/macro';

import type { Timeslot, Timeslots } from 'types';
import { time, parseDate } from 'utils/datetime';
import styles from './DayAvailabilityMultiSpec.scss';

type Props = {|
  baseUrl: string,
  date: string,
  timeslots: Timeslots,
  maxLength: number,
  onClick: Timeslot => void,
  hasOurBooking: boolean,
|};

const DayAvailabilityMultiSpec = (props: Props) => {
  const { hasOurBooking, baseUrl, date, timeslots, maxLength, onClick } = props;
  let fixedSlots;

  if (timeslots.length >= maxLength) {
    fixedSlots = timeslots.slice(0, maxLength);
  } else {
    fixedSlots = [...timeslots, ...new Array(maxLength - timeslots.length)];
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <strong>
          <DateFormat
            value={parseDate(date)}
            format={{
              weekday: 'long',
            }}
          />
        </strong>
        <br />
        <DateFormat
          value={parseDate(date)}
          format={{
            month: 'short',
            day: '2-digit',
          }}
        />
      </div>
      {fixedSlots.map(
        (slot, index) =>
          slot ? (
            <div key={slot.date} className="slot availiable-slot">
              {hasOurBooking ? (
                <Link to="/patient/reason" className={styles.timeslot} onClick={() => onClick(slot)}>
                  {time(slot.date)}
                </Link>
              ) : (
                <a
                  href={`${baseUrl}calendar_id=${slot.clicrdvCalendarId}&date=${slot.date.toString()}`}
                  className={styles.timeslot}
                  onClick={() => onClick(slot)}
                >
                  {time(slot.date)}
                </a>
              )}
            </div>
          ) : (
            // eslint-disable-next-line
            <div key={index} className="slot empty-slot">
              <div className={styles.emptyslot} />
            </div>
          )
      )}
    </div>
  );
};

export default DayAvailabilityMultiSpec;
