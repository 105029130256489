import React from 'react';
import PropTypes from 'prop-types';

import trackEvent from 'utils/trackEvent';

const WebsiteLink = ({ website, profileType, profileTypeId, profileTypeName }) => {
  const handleClick = () => {
    trackEvent('Paid Traction', 'Visit Website', `${profileType} (${profileTypeId}) ${profileTypeName}`);
  };

  const link = website.includes('http') ? website : `http://${website}`;

  return (
    <a href={link} target="_blank" rel="noopener noreferrer" onClick={handleClick} className="hidden-xs">
      {link}
    </a>
  );
};

WebsiteLink.propTypes = {
  website: PropTypes.string.isRequired,
  profileType: PropTypes.string.isRequired,
  profileTypeId: PropTypes.number.isRequired,
  profileTypeName: PropTypes.string.isRequired,
};

export default WebsiteLink;
