// @flow

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import type { Dispatch } from 'redux';
import { NumberFormat } from '@lingui/macro';

import config from 'config';
import type { PriceListType } from 'types';
import type { ShowPriceListModal } from 'modules/modal/actions';
import { modalActions } from 'modules/modal/actions';

import styles from './PriceList.scss';

type Props = {
  specialistId: number,
  specialistPracticeId: number,
  services: PriceListType,
  allowEdit: boolean,
  dispatch: Dispatch<ShowPriceListModal>,
};

const PriceList = (props: Props) => {
  const { specialistId, specialistPracticeId, services, allowEdit, dispatch } = props;

  return (
    <Fragment>
      {services.map(item => {
        if (item.name) {
          return (
            <div key={item.id} className={classNames('is-clearfix mt-10 pb-5', styles.row)}>
              <span className="has-text-grey-dark has-text-weight-semibold is-pulled-left">{item.name}</span>
              <span className="is-pulled-right">
                <NumberFormat
                  value={item.price}
                  format={{
                    style: 'currency',
                    currency: config.currency.code,
                    minimumFractionDigits: config.currency.minimumFractionDigits,
                  }}
                />
              </span>
            </div>
          );
        }
        return null;
      })}
      {allowEdit && (
        <span
          role="button"
          tabIndex={0}
          onClick={() => dispatch(modalActions.showPriceListModal(specialistId, specialistPracticeId, services))}
          className="is-pulled-right mt-10"
        >
          <img src="/images/pencil.png" alt="pencil" className="ml-10" />
        </span>
      )}
    </Fragment>
  );
};

PriceList.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.number,
    })
  ),
  specialistId: PropTypes.number.isRequired,
  specialistPracticeId: PropTypes.number.isRequired,
  allowEdit: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(PriceList);
