import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { modalActions } from 'modules/modal/actions';
import styles from './DescriptionCard.scss';

class DescriptionCard extends PureComponent {
  getTitle = () => {
    const { id, titleIconUrl, title, isCompacted } = this.props;
    return (
      <div id={id} className={classNames('is-size-5-5 has-text-weight-semibold has-text-grey-dark', styles.subTitle)}>
        <div className={styles.title}>
          {titleIconUrl && (
            <span className={styles.titleIcon}>
              <img src={titleIconUrl} alt="iconUrl" />
            </span>
          )}
          {title}
        </div>
        {isCompacted && (
          <div role="button" tabIndex={0} onClick={this.onTitleArrowClick} className="has-text-grey-lighter">
            <i className="fa fa-plus fa-2x" />
          </div>
        )}
      </div>
    );
  };

  getFeatureBlock = () => {
    const { title, isCompacted, children } = this.props;
    return (
      <div className={classNames(styles.featureBlock)}>
        {isCompacted && (
          <Fragment>
            <div className="is-size-3 has-text-weight-light has-text-primary">{title}</div>
            <div className={classNames('is-divider', styles.divider)} />
          </Fragment>
        )}
        {children}
      </div>
    );
  };

  render() {
    const { dispatch, contentClassNames, isCompacted } = this.props;
    const titleComponent = this.getTitle();
    const featureBlock = this.getFeatureBlock();
    return (
      <div className={styles.card}>
        <div className={classNames(styles.content, contentClassNames)}>
          {isCompacted ? (
            <div role="button" tabIndex={0} onClick={() => dispatch(modalActions.showSimpleModal(featureBlock))}>
              {titleComponent}
            </div>
          ) : (
            <Fragment>
              {titleComponent}
              {featureBlock}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

DescriptionCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  contentClassNames: PropTypes.string,
  titleIconUrl: PropTypes.string,
  isCompacted: PropTypes.bool,
  id: PropTypes.string,
};

DescriptionCard.defaultProps = {
  isCompacted: false,
  id: '',
};

export default connect()(DescriptionCard);
