import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from './actions';
import SpecialistView from './SpecialistView';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSpecialist: actions.fetchSpecialist,
      updateSpecialistProfile: actions.updateSpecialistProfile,
      dispatch,
    },
    dispatch
  );

function mapStateToProps(appState) {
  return {
    ...appState.profileView,
    browser: appState.browser,
    userId: appState.user.userId,
    isAdminRole: appState.user.isAdminRole,
    editableSpecialists: appState.user.editableSpecialists,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialistView);
