import { connect } from 'react-redux';

import SpecialistFull from './SpecialistFull';
import { selectors } from '../reducer';

const mapStateToProps = state => ({
  browser: state.browser,
  activeTab: selectors.getActiveTab(state),
});

export default connect(mapStateToProps)(SpecialistFull);
