import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import classnames from 'classnames';
import styles from './Map.scss';

class SpecialistMap extends PureComponent {
  state = {
    isOpen: 0,
  };

  onToggleOpen = id => {
    this.setState({
      isOpen: id,
    });
  };

  render() {
    const { practice } = this.props;
    return (
      <GoogleMap defaultZoom={12} center={{ lat: practice.latitude, lng: practice.longitude }}>
        <Marker
          key={practice.id}
          icon="/images/new/locator.png"
          position={{ lat: practice.latitude, lng: practice.longitude }}
          onClick={() => this.onToggleOpen(practice.id)}
        >
          {this.state.isOpen === practice.id && (
            <InfoWindow onCloseClick={() => this.onToggleOpen(0)}>
              <div className={styles.container}>
                <div className={styles.info}>
                  <div className={classnames(styles.row, 'is-size-6 has-text-weight-semibold has-text-grey-dark')}>
                    {practice.name}
                  </div>
                  <div className={classnames('has-text-weight-semibold', styles.row, styles.specialization)}>
                    {practice.specialization}
                  </div>
                  <div
                    className={classnames('has-text-weight-semibold has-text-grey-dark', styles.row, styles.address)}
                  >
                    {practice.town} {practice.postCode}
                    <br />
                    {practice.address}
                  </div>
                </div>
              </div>
            </InfoWindow>
          )}
        </Marker>
      </GoogleMap>
    );
  }
}

SpecialistMap.propTypes = {
  practice: PropTypes.shape({
    id: PropTypes.number,
    latitude: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};

export default withGoogleMap(SpecialistMap);
