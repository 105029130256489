import React from 'react';
import PropTypes from 'prop-types';

import ProfileLink from 'components/link/ProfileLink';
import Transportation from './transportation';
import Map from './map';
import styles from './Location.scss';

const Location = ({ practice, allowEdit }) => (
  <div className={styles.mapSectionContent}>
    <div className="columns">
      <div className="column is-6">
        <ProfileLink
          className="is-size-5-5 has-text-weight-semibold"
          profileType="practice"
          town={practice.town}
          borough={practice.borough}
          slug={practice.slug}
        >
          {practice.name}
        </ProfileLink>
        <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
          <span itemProp="addressLocality">{practice.town}</span> <span itemProp="postalCode">{practice.postCode}</span>
          <br />
          <span itemProp="streetAddress">{practice.address}</span>
        </div>

        {(allowEdit || practice.bus || practice.underground) && (
          <Transportation
            practiceId={practice.id}
            underground={practice.underground}
            bus={practice.bus}
            allowEdit={allowEdit}
          />
        )}
      </div>

      <Map practice={practice} />
    </div>
  </div>
);

Location.defaultProps = {
  allowEdit: false,
};

Location.propTypes = {
  practice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    town: PropTypes.string.isRequired,
    postCode: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    underground: PropTypes.string,
    bus: PropTypes.string,
  }).isRequired,
  allowEdit: PropTypes.bool,
};

export default Location;
