import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';

import { longDate } from 'utils/datetime';
import config from 'config';
import StarRating from 'components/starRating';
import styles from './Review.scss';

const Review = ({ review, isMobile }) =>
  review && review.approved ? (
    <div className={styles.review} itemProp="review" itemScope itemType="http://schema.org/Review">
      <div
        itemProp="reviewRating"
        itemScope
        itemType="http://schema.org/Rating"
        className={classNames('columns mb-0', styles.rating)}
      >
        <div className="column pl-0">
          <div className="is-size-5-5 is-size-7-mobile has-text-weight-semibold has-text-grey-dark">
            <Trans>Overall Experience</Trans>
          </div>
          <StarRating value={review.overallRating} size={14} />
          <meta itemProp="ratingValue" content={review.overallRating} />
        </div>
        <div className="column">
          <div className="is-size-5-5 is-size-7-mobile has-text-weight-semibold has-text-grey-dark">
            <Trans>Wait Time</Trans>
          </div>
          <StarRating value={review.waitTimeRating} size={14} />
        </div>
        <div className="column">
          <div className="is-size-5-5 is-size-7-mobile has-text-weight-semibold has-text-grey-dark">
            <Trans>Bedside Manner</Trans>
          </div>
          <StarRating value={review.bedsideMannerRating} size={14} />
        </div>
      </div>
      <div
        itemProp="reviewBody"
        className={classNames('has-text-grey-dark', {
          'has-text-weight-semibold': !isMobile,
        })}
      >
        {review.text}
      </div>
      <div className={styles.contacts}>
        <div className={styles.contactItem} itemProp="datePublished">
          {longDate(review.date)}
        </div>
        <div className={styles.contactItem} itemProp="author">
          <Trans>{review.name}, verified patient</Trans>
        </div>
        <div className={styles.contactItem}>
          <Trans>Source: {config.name}</Trans>
        </div>
      </div>
    </div>
  ) : null;
Review.propTypes = {
  isMobile: PropTypes.bool,
  review: PropTypes.shape({
    id: PropTypes.number.isRequired,
    approved: PropTypes.bool,
    name: PropTypes.string,
    overallRating: PropTypes.number,
    waitTimeRating: PropTypes.number,
    bedsideMannerRating: PropTypes.number,
    text: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
};

export default Review;
