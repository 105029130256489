// @flow

import { connect } from 'react-redux';

import { selectors } from 'modules/bookingCalendar/reducer';
import CalendarSection from './CalendarSection';

const mapStateToProps = (state, ownProps) => ({
  availableCalendarTimeSlots: selectors.getAvailableCalendarTimeslots(state, ownProps.practice),
  availableCalendarReasons: selectors.getAvailableCalendarReasons(state, ownProps.practice),
});

export default connect(mapStateToProps)(CalendarSection);
