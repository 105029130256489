/* global window */
import React from 'react';

import Map from './Map';
import styles from './Map.scss';

class MapWrapper extends React.Component {
  state = {
    ready: false,
  };

  componentDidMount() {
    if (document.readyState === 'complete') {
      this.resetMap();
    } else {
      window.addEventListener('load', this.resetMap, false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.resetMap, false);
  }

  resetMap = () => {
    this.setState({
      ready: true,
    });
  };

  render() {
    return this.state.ready ? (
      <Map
        {...this.props}
        mapElement={<div className={styles.map} />}
        containerElement={<div className={styles.wrapper} />}
      />
    ) : null;
  }
}

export default MapWrapper;
