// @flow

import React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';

import styles from './BookingSectionTitle.scss';

const BookingSectionTitle = () => (
  <div className={classNames('has-magic-gradient', styles.bookingTitle)}>
    <div className="is-size-4 has-text-white has-text-weight-semibold">
      <Trans>Book an appointment online</Trans>
    </div>
    <div className="has-text-white">
      <Trans>Complete the following information</Trans>
    </div>
  </div>
);

export default BookingSectionTitle;
