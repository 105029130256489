import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { connect } from 'react-redux';

import { modalActions } from 'modules/modal/actions';

import Review from './review';
import styles from './Reviews.scss';

class Reviews extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAllReviews: false,
    };

    if (props.hash === '#review') {
      props.dispatch(modalActions.showReviewModal(props.specialist.id));
    }
  }

  toggleShow = () => {
    this.setState(prevState => ({
      showAllReviews: !prevState.showAllReviews,
    }));
  };

  render() {
    const { specialist, isMobile, dispatch } = this.props;
    const { showAllReviews } = this.state;

    return (
      <div>
        <div className={styles.title}>
          <button
            onClick={() => dispatch(modalActions.showReviewModal(specialist.id))}
            className={`button button-link has-text-link pl-0 ${styles.reviewLink}`}
          >
            <Trans>Write a Review</Trans>
          </button>
        </div>
        <div>
          {specialist.reviews &&
            specialist.reviews.length > 0 &&
            !showAllReviews && (
              <Review isMobile={isMobile} key={specialist.reviews[0].id} review={specialist.reviews[0]} />
            )}

          {specialist.reviews &&
            specialist.reviews.length > 0 &&
            showAllReviews &&
            specialist.reviews.map(review => <Review isMobile={isMobile} key={review.id} review={review} />)}
        </div>

        {specialist.reviews &&
          specialist.reviews.length > 1 && (
            <button
              onClick={this.toggleShow}
              className="button button-link has-text-link pl-0 is-size-6-5"
              jest="ft-show-link"
            >
              <span className="icon is-small">
                <i className={`fa ${showAllReviews ? 'fa-angle-up' : 'fa-angle-down'}`} />
              </span>
              <span>{showAllReviews ? <Trans>Show Less</Trans> : <Trans>Show More</Trans>}</span>
            </button>
          )}
      </div>
    );
  }
}

Reviews.defaultProps = {
  hash: '',
};

Reviews.propTypes = {
  isMobile: PropTypes.bool,
  specialist: PropTypes.shape({
    id: PropTypes.number.isRequired,
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        approved: PropTypes.bool,
        name: PropTypes.string,
        overallRating: PropTypes.number,
        text: PropTypes.string,
        date: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  hash: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Reviews);
