import React from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import TinyMCE from 'react-tinymce';

const TinyMCEWrapper = props => {
  const { onChange, onSave, content } = props;
  return (
    <div className="clearfix">
      <TinyMCE
        content={content}
        config={{
          // content_css: '/css/style.scss',
          body_class: 'text-16',
          invalid_elements: 'script',
          height: 300,
          plugins: 'autolink link paste',
          content_style: 'div { padding:10px; margin: 0; fontSize: 16px; }',
          toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | link',
          extended_valid_elements: 'a[href|target=_blank]',
          link_title: false,
          target_list: false,
          paste_as_text: true,
        }}
        onChange={onChange}
      />

      <br />

      <button className="button is-primary" onClick={onSave}>
        <i className="fa fa-check" />
        &nbsp;&nbsp; <Trans>Save</Trans>
      </button>
    </div>
  );
};

TinyMCEWrapper.propTypes = {
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
};

export default TinyMCEWrapper;
