import React from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { modalActions } from 'modules/modal/actions';

import styles from './Transportation.scss';

const Transportation = ({ practiceId, bus, underground, allowEdit, dispatch }) => (
  <div className="mt-20">
    <h6 className="is-size-5-5 mb-10 has-text-weight-semibold has-text-grey-dark is-uppercase">
      <Trans>TRANSPORTATION</Trans>
      {allowEdit && (
        <span
          role="button"
          tabIndex={0}
          onClick={() => dispatch(modalActions.showTransportationModal(practiceId, bus, underground))}
          className={styles.reviewLink}
        >
          <img src="/images/pencil.png" alt="pencil" className="ml-10" />
        </span>
      )}
    </h6>

    {underground && <p className={`mb-10 ${styles.underground}`}> {underground}</p>}
    {bus && <p className={`mb-10 ${styles.bus}`}> {bus}</p>}
  </div>
);

Transportation.defaultProps = {
  allowEdit: false,
};

Transportation.propTypes = {
  practiceId: PropTypes.number,
  underground: PropTypes.string,
  bus: PropTypes.string,
  allowEdit: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Transportation);
