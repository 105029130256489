import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { connect } from 'react-redux';

import { modalActions } from 'modules/modal/actions';

import SearchLink from 'components/link/SearchLink';

const Languages = props => {
  const { allowEdit, town, specialist } = props;
  const { specialization, languages } = specialist;

  return allowEdit || (languages && languages.length > 0) ? (
    <div>
      <div className="is-size-5-5 has-text-grey-dark has-text-weight-semibold is-clearfix">
        <div className="is-pulled-left">
          <Trans>LANGUAGES</Trans>
        </div>
        {allowEdit && (
          <span
            role="button"
            tabIndex={0}
            onClick={() => props.dispatch(modalActions.showLanguageModal(specialist.id, specialist.languages))}
          >
            <img src="/images/pencil.png" alt="pencil" className="ml-10" />
          </span>
        )}
      </div>
      <div>
        <ul>
          {languages.map(language => (
            <li key={language.id}>
              <SearchLink town={town} speciality={specialization} language={language.name}>
                {language.name}
              </SearchLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ) : null;
};

Languages.propTypes = {
  allowEdit: PropTypes.bool.isRequired,
  specialist: PropTypes.shape({
    languages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    specialization: PropTypes.string,
  }).isRequired,
  town: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Languages);
