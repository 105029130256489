import React from 'react';
import PropTypes from 'prop-types';

const SpecialistPicture = ({ specialistId, profilePicture }) => (
  <img
    src={
      profilePicture > 0
        ? `/images/profile/Id${specialistId}-ProfilPic.jpg`
        : '/images/profile/default/default-profile-picture.png'
    }
    alt=""
    width="150"
    itemProp="image"
    style={{ borderRadius: '50%' }}
  />
);

SpecialistPicture.propTypes = {
  specialistId: PropTypes.number,
  profilePicture: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

export default SpecialistPicture;
