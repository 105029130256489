import React from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

import PhoneNumber from 'components/phoneNumber';
import WebsiteLink from 'components/link/WebsiteLink';

const Contacts = props => (
  <div className="columns">
    <div className="column">
      {props.phoneNumber && (
        <div className="mb-20">
          <h6 className="is-size-5-5 mb-10 has-text-weight-semibold has-text-grey-dark is-uppercase">
            <Trans>Phone Number</Trans>
          </h6>
          <PhoneNumber
            phoneNumber={props.phoneNumber}
            profileType={props.profileType}
            profileTypeId={props.profileTypeId}
            profileTypeName={props.profileTypeName}
          />
        </div>
      )}
      {props.website && (
        <div>
          <h6 className="is-size-5-5 mb-10 has-text-weight-semibold has-text-grey-dark is-uppercase">
            <Trans>Website</Trans>
          </h6>
          <WebsiteLink
            website={props.website}
            profileType={props.profileType}
            profileTypeId={props.profileTypeId}
            profileTypeName={props.profileTypeName}
          />
        </div>
      )}
    </div>
  </div>
);

Contacts.propTypes = {
  profileType: PropTypes.string,
  profileTypeId: PropTypes.number,
  profileTypeName: PropTypes.string,
  phoneNumber: PropTypes.string,
  website: PropTypes.string,
};

export default Contacts;
