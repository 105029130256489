import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Route } from 'react-router-dom';

import UniversalComponent from 'utils/universalComponent';
import * as specialistsConstants from 'modules/specialist/constants';
import SpecialistTitleHelper from 'modules/admin/specialists/specialist.title.helper';

// Can not use react-universal-component as first rendering happens before specialist is loaded and we do not know which
import SpecialistFull from './specialistFull';

export default class SpecialistView extends PureComponent {
  componentDidMount() {
    if (this.props.match.params) {
      this.fetchCurrentSpecialist();
    }
  }

  componentDidUpdate(nextProps) {
    const { match } = this.props;
    if (match.params.slug !== nextProps.match.params.slug) {
      this.fetchCurrentSpecialist();
    }
  }

  fetchCurrentSpecialist() {
    const { props } = this;
    props.fetchSpecialist(props.match.params.slug, props.match.params.specialization);
  }

  getProfile = () => {
    const { specialist } = this.props;
    const fullUser = specialist.level > specialistsConstants.FREE_PROFILE;
    const allowEdit = this.isAllowEdit();
    if (fullUser) {
      const fullUserProps = Object.assign(this, this.props, { allowEdit });
      return <SpecialistFull {...fullUserProps} />;
    }
    return <Route render={this.renderSpecialistFree} />;
  };

  isAllowEdit = () => {
    const { userId, isAdminRole, editableSpecialists, specialist } = this.props;
    return (
      !!userId && (specialist.userId === userId || isAdminRole || editableSpecialists.some(id => specialist.id === +id))
    );
  };

  renderNotFoundView = () => <UniversalComponent page="notFoundView" />;

  renderSpecialistFree = () => {
    const { specialist } = this.props;
    const allowEdit = this.isAllowEdit();
    return <UniversalComponent page="specialistFree" specialist={specialist} allowEdit={allowEdit} />;
  };

  render() {
    const { specialist } = this.props;

    if (specialist) {
      if (Object.keys(specialist).length > 0) {
        if (specialist.practices && specialist.practices.length > 0) {
          const profile = this.getProfile();

          const title = `${SpecialistTitleHelper.getTitle(specialist)} - Findoc`;

          return (
            <React.StrictMode>
              <Helmet
                title={title}
                script={[
                  {
                    src: '//cdnjs.cloudflare.com/ajax/libs/tinymce/4.8.5/tinymce.min.js',
                  },
                ]}
              />
              {profile}
            </React.StrictMode>
          );
        }
        return <Route render={this.renderNotFoundView} />;
      }
    }
    return <Route render={this.renderNotFoundView} />;
  }
}

SpecialistView.defaultProps = {
  editingProfile: false,
  userId: undefined,
};

SpecialistView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fetchSpecialist: PropTypes.func.isRequired,
  updateSpecialistProfile: PropTypes.func.isRequired,
  specialist: PropTypes.shape({
    id: PropTypes.number,
    userId: PropTypes.number,
    level: PropTypes.number,
    practices: PropTypes.array,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string.isRequired,
      specialization: PropTypes.string.isRequired,
    }),
  }).isRequired,
  editingProfile: PropTypes.bool,
  userId: PropTypes.number,
  isAdminRole: PropTypes.bool.isRequired,
  editableSpecialists: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};
