// @flow

import { connect } from 'react-redux';

import BookingCalendarMultiSpec from './BookingCalendarMultiSpec';

const mapStateToProps = ({ browser }) => ({
  tabletBreakpoint: browser.lessThan.tabletBreakpoint,
});

export default connect(mapStateToProps)(BookingCalendarMultiSpec);
