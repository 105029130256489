// @flow

import { connect } from 'react-redux';

import DescriptionCard from './DescriptionCard';

const mapStateToProps = ({ browser }) => ({
  isCompacted: browser.lessThan.aboveMobileBreakpoint,
});

export default connect(mapStateToProps)(DescriptionCard);
