import React from 'react';
import PropTypes from 'prop-types';
import StarRatings from 'react-star-ratings';

import colors from 'styles/_colors.scss';

const StarRatingComponent = ({ value, editing, size }) => (
  <StarRatings
    rating={value}
    isSelectable={editing || false}
    numOfStars={5}
    starRatedColor={colors.warning}
    starEmptyColor={colors.greyLight}
    starDimension={`${size}px`}
    starSpacing="1px"
  />
);

StarRatingComponent.defaultProps = {
  editing: false,
};

StarRatingComponent.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  editing: PropTypes.bool,
};

export default StarRatingComponent;
