import React from 'react';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import classNames from 'classnames';

import { i18n } from 'utils/i18n';
import BookingCalendarMultiSpec from 'components/booking/BookingCalendarMultiSpec';
import BookingSectionTitle from 'components/booking/bookingSectionTitle';
import { actions as bookingCalendarActions } from 'modules/bookingCalendar/actions';
import { modalActions } from 'modules/modal/actions';
import styles from './CalendarSection.scss';

class CalendarSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedReason: { id: '' },
      timeSlots: [],
    };
    this.fetchBookingReasons(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.practice.id !== nextProps.practice.id) {
      this.fetchBookingReasons(nextProps);
      this.setState({
        selectedReason: { id: '' },
        timeSlots: [],
      });
    }

    if (this.props.availableCalendarReasons !== nextProps.availableCalendarReasons) {
      const { availableCalendarReasons } = nextProps;
      // load timeslots immediately if there is only one
      if (availableCalendarReasons.length === 1) {
        this.setReason(availableCalendarReasons[0], nextProps);
      }
    }

    if (
      nextProps.availableCalendarTimeSlots.availabilities &&
      this.props.availableCalendarTimeSlots !== nextProps.availableCalendarTimeSlots
    ) {
      const { availabilities } = nextProps.availableCalendarTimeSlots;
      const timeSlots = availabilities.map(availability => ({
        date: availability,
        clicrdvCalendarId: this.props.practice.clicrdvCalendarId,
      }));
      this.setState({ timeSlots });
    }
  }

  setReason(selectedReason, props) {
    this.setState({
      selectedReason,
    });

    if (selectedReason.id) {
      const { practice } = props;
      const { apiType, clicrdvGroupId, clicrdvCalendarId } = practice;

      this.props.dispatch(
        bookingCalendarActions.fetchAvailableTimeSlots(
          apiType,
          clicrdvGroupId,
          [clicrdvCalendarId],
          selectedReason.id,
          false,
          practice.id
        )
      );
    }
  }

  handleReasonChange = e => {
    const selectedReason = this.props.availableCalendarReasons.find(item => item.id === e.target.value * 1) || {};

    this.setReason(selectedReason, this.props);
  };

  fetchBookingReasons(props) {
    const { specialist, practice } = props;

    const { apiType, clicrdvGroupId, clicrdvCalendarId } = practice;

    this.props.dispatch(
      bookingCalendarActions.fetchAvailableBookingReasonList(
        apiType,
        clicrdvGroupId,
        [clicrdvCalendarId],
        specialist.id
      )
    );
  }

  shouldChooseConsultationType() {
    return this.props.availableCalendarReasons.length > 1 && !this.state.selectedReason.id;
  }

  render() {
    const { availableCalendarTimeSlots, availableCalendarReasons, specialist, practice, dispatch } = this.props;

    const { selectedReason, timeSlots } = this.state;
    const hideEnquireButton = this.shouldChooseConsultationType();
    const defaultText = this.shouldChooseConsultationType() ? i18n._(t`Please choose a consultation type.`) : undefined;

    const placeOfConsultationTrigger = (
      <div>
        <div className="is-size-5-5 has-text-weight-semibold has-text-primary mt-15">{practice.name}</div>
        <small id="jest-place">
          {practice.address},{practice.town} {practice.postCode}
        </small>
      </div>
    );

    return (
      <div className={styles.bookingSection}>
        <div className={styles.bookingContent}>
          <div className={styles.container}>
            <BookingSectionTitle practices={specialist.practices} />
            <div className="mt-25">
              <ul className={styles.steps}>
                {specialist.practices.length > 1 && (
                  <li className={classNames(availableCalendarReasons.length > 0 && styles.checked, 'pb-15')}>
                    {availableCalendarReasons.length > 0 ? (
                      <div className={styles.check} />
                    ) : (
                      <div className={styles.no}>1</div>
                    )}
                    <Trans>Choose a place</Trans>
                    <div
                      role="button"
                      tabIndex={0}
                      jest="jest-practiceChange"
                      onClick={() => dispatch(modalActions.showPracticeSelectModal(specialist.practices, practice.id))}
                    >
                      {placeOfConsultationTrigger}
                    </div>
                  </li>
                )}
                {availableCalendarReasons.length > 0 && (
                  <li className={classNames(selectedReason.id && styles.checked, 'pb-15')}>
                    {selectedReason.id ? (
                      <div className={styles.check} />
                    ) : (
                      <div className={styles.no}>{specialist.practices.length > 1 ? 2 : 1}</div>
                    )}
                    <Trans>Choose a consultation type</Trans>
                    <div className="select is-fullwidth mt-20 mb-15 ft-reason-select">
                      <select value={selectedReason.id} onChange={this.handleReasonChange}>
                        <option disabled value="">
                          {i18n._(t`Select`)}
                        </option>
                        {availableCalendarReasons.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.publicname}
                          </option>
                        ))}
                      </select>
                    </div>
                  </li>
                )}
                {availableCalendarReasons.length === 0 ||
                  (selectedReason.id && (
                    <li className="pb-15">
                      <div className={styles.no}>{specialist.practices.length > 1 ? 3 : 2}</div>
                      <Trans>Choose your slot</Trans>
                    </li>
                  ))}
              </ul>
            </div>

            <div className={classNames(defaultText && styles.hasSlots, styles.calendarsContainer)}>
              <BookingCalendarMultiSpec
                key={`BookingCalendarMultiSpec-${practice.name}`}
                practice={practice}
                specialists={[specialist]}
                spinnerId={specialist.id}
                firstTimeSlot={availableCalendarTimeSlots.firstSlot}
                timeslots={timeSlots}
                defaultText={defaultText}
                hideEnquireButton={hideEnquireButton}
                interventionId={selectedReason.id}
                interventionName={selectedReason.publicname}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CalendarSection.propTypes = {
  availableCalendarTimeSlots: PropTypes.shape({
    firstSlot: PropTypes.string,
    availabilities: PropTypes.arrayOf(PropTypes.string),
  }),
  specialist: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  practice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    address: PropTypes.string,
    postCode: PropTypes.string,
    town: PropTypes.string,
    apiType: PropTypes.string,
    clicrdvGroupId: PropTypes.number,
    clicrdvCalendarId: PropTypes.number,
  }),
  availableCalendarReasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      publicname: PropTypes.string,
    })
  ),
  dispatch: PropTypes.func.isRequired,
};

export default CalendarSection;
