// @flow

/* eslint max-len: 0 */
import * as React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';

import type { PracticeState, AppointmentSpecialist, Timeslot, Timeslots } from 'types';
import { addDay, isoDate, mediumDate } from 'utils/datetime';
import { setAppointment } from 'utils/storage';
import trackEvent from 'utils/trackEvent';
import LoadingSpinner from 'components/loadingSpinner';
import EnquireButton from 'components/enquireButton';
import DayAvailabilityMultiSpec from './DayAvailabilityMultiSpec';
import styles from './BookingCalendarMultiSpec.scss';

type Props = {|
  tabletBreakpoint: boolean,
  mobileCount: number,
  desktopCount: number,
  interventionId: ?number,
  interventionName: string,
  practice: PracticeState,
  specialists: Array<AppointmentSpecialist>,
  firstTimeSlot: Date,
  spinnerId: number,
  timeslots: Timeslots,
  defaultText: React.Element<typeof Trans>,
  hideEnquireButton: boolean,
|};

type State = {
  showMore: boolean,
  dayLimit: number,
  startDate: Date,
  slotLimit: number,
};

class BookingCalendarMultiSpec extends React.PureComponent<Props, State> {
  static defaultProps = {
    desktopCount: 4,
    mobileCount: 3,
    interventionName: '',
    interventionId: undefined,
    hideEnquireButton: false,
    defaultText: <Trans>This practitioner does not have online availabilities</Trans>,
  };

  state = {
    showMore: false,
    dayLimit: this.props.tabletBreakpoint ? this.props.mobileCount : this.props.desktopCount,
    startDate: new Date(),
    slotLimit: 4,
  };

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.tabletBreakpoint !== nextProps.tabletBreakpoint) {
      this.setState({
        dayLimit: nextProps.tabletBreakpoint ? this.props.mobileCount : this.props.desktopCount,
      });
    }
    if (this.props.interventionId !== nextProps.interventionId) {
      this.setState({
        startDate: new Date(),
      });
    }
  }

  groupTimeslots = (timeslots: Timeslots) => {
    const grouped = [];

    for (let i = 0; i < this.state.dayLimit; i += 1) {
      const date = isoDate(addDay(this.state.startDate, i));
      const slots: Timeslots = timeslots.filter(item => isoDate(item.date) === date);

      grouped.push({
        date,
        slots,
      });
    }

    return grouped;
  };

  handleTimeSlotClick = (timeslot: Timeslot) => {
    const { practice, specialists, interventionId, interventionName } = this.props;

    let currentSpecialist = specialists[0];
    if (specialists && specialists.length > 1) {
      // get current specialist from clicked slot by calendar id
      currentSpecialist = specialists.find(specialist => specialist.clicrdvCalendarId === timeslot.clicrdvCalendarId);
    }

    if (currentSpecialist) {
      trackEvent(
        'Paid Traction',
        'Choose Time Slot',
        `specialist (${currentSpecialist.id}) ${currentSpecialist.firstName} ${currentSpecialist.lastName}`
      );

      setAppointment({
        specialist: currentSpecialist,
        clicrdvCalendarId: timeslot.clicrdvCalendarId,
        practice,
        interventionName,
        timeslot,
        ...(interventionId ? { interventionId } : {}),
      });
    }
  };

  handleShowMore = () => {
    this.setState({
      showMore: true,
    });
  };

  handleDayChange = (startDate: Date) => {
    this.setState({
      startDate,
      showMore: false,
    });
  };

  handlePrevDays = () => {
    this.handleDayChange(addDay(this.state.startDate, -this.state.dayLimit));
  };

  handleNextDays = () => {
    this.handleDayChange(addDay(this.state.startDate, this.state.dayLimit));
  };

  render() {
    const { practice, timeslots, interventionId, spinnerId, hideEnquireButton } = this.props;
    const { clicrdvGroupName } = practice;

    const { showMore, startDate } = this.state;

    // make timendo base url
    let baseUrl = `https://www.timendo.com/${clicrdvGroupName || ''}?`;
    if (interventionId) {
      baseUrl += `intervention_ids[]=${interventionId}&`;
    }

    let availabilities;
    if (timeslots && timeslots.length === 1 && Object.keys(timeslots[0]).length === 0) {
      availabilities = [];
    } else {
      availabilities = this.props.timeslots;
    }
    // limit days
    const limitedAvailabilities = this.groupTimeslots(availabilities);
    const maxLength = Math.max(0, ...limitedAvailabilities.map(item => item.slots.length));
    const visibleLength = showMore ? maxLength : this.state.slotLimit;

    return (
      <React.Fragment>
        <LoadingSpinner track={`calendar_${spinnerId}`} />
        <div className={styles.calendarWrapper}>
          <button
            className={classNames(styles.btnNav, styles.btnPrev)}
            disabled={isoDate(startDate) <= isoDate(new Date())}
            onClick={this.handlePrevDays}
          >
            <svg width="10" height="14" viewBox="0 0 10 16">
              <path d="M6.7.5c.2-.3.5-.5 1-.5s.8.2 1.2.5c.5.6.5 1.4 0 2.2L3.5 8 9 13.5c.6.8.6 1.5 0 2.2-.4.3-.8.4-1.3.4-.4 0-.8 0-1-.3L.4 9C0 9 0 8.6 0 8c0-.4 0-.8.4-1L6.7.5z" />
            </svg>
          </button>
          <button
            className={`${styles.btnNav} ${styles.btnNext}`}
            onClick={this.handleNextDays}
            disabled={!maxLength && !this.props.firstTimeSlot}
          >
            <svg width="10" height="14" viewBox="0 0 10 16">
              <path d="M2.7.5c-.2-.3-.5-.5-1-.5S1 .2.5.5C-.2 1-.2 2 .5 2.7L5.7 8 .5 13.5c-.7.8-.7 1.5 0 2.2.3.3.7.4 1.2.4.4 0 .8 0 1-.3L9 9c.3-.2.4-.5.4-1 0-.4 0-.8-.4-1L2.7.5z" />
            </svg>
          </button>
          {!maxLength && (
            <div className={styles.messageOverlay}>
              <div className={styles.noMessage}>
                {this.props.firstTimeSlot ? (
                  <div
                    role="button"
                    tabIndex={0}
                    className="button button-primary has-text-link is-small"
                    jest="jest-nextAppointment"
                    onClick={() => this.handleDayChange(this.props.firstTimeSlot)}
                  >
                    <Trans>Next appointment {mediumDate(this.props.firstTimeSlot)}</Trans>{' '}
                    <i className="fa fa-angle-right fa-2x ml-5 mb-5" />
                  </div>
                ) : (
                  this.props.defaultText
                )}
              </div>
            </div>
          )}
          <div className={styles.calendar}>
            {limitedAvailabilities.map(item => (
              <DayAvailabilityMultiSpec
                key={item.date}
                date={item.date}
                timeslots={item.slots}
                hasOurBooking={practice.hasOurBooking}
                baseUrl={`${baseUrl}`}
                maxLength={visibleLength}
                onClick={this.handleTimeSlotClick}
              />
            ))}
          </div>
        </div>
        <div className="has-text-centered">
          {!showMore &&
            maxLength > this.state.slotLimit && (
              <button className="button button-link has-text-link" onClick={this.handleShowMore} jest="jest-showmore">
                <Trans>See more slots</Trans>
              </button>
            )}
          {!maxLength &&
            !this.props.firstTimeSlot &&
            !timeslots.length &&
            !hideEnquireButton && (
              <EnquireButton
                profileType="practice"
                profileTypeId={practice.id}
                profileTypeName={practice.name}
                text={<Trans>Request a callback</Trans>}
                componentClassName="button button-link has-text-link"
              />
            )}
        </div>
      </React.Fragment>
    );
  }
}

export default BookingCalendarMultiSpec;
