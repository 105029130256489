import React, { PureComponent } from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import About from 'components/profile/about';
import DescriptionCard from 'components/profile/descriptionCard';
import PracticeLocation from 'components/profile/practice/location';
import Contacts from 'components/profile/practice/contacts';
import ProfileLink from 'components/link/ProfileLink';
import { actions as specialistActions } from 'modules/specialist/actions';
import PriceList from 'modules/specialist/components/priceList';
import { modalActions } from 'modules/modal/actions';

import Languages from '../components/languages';
import SpecialistHeader from '../components/specialistHeader';
import Reviews from '../components/reviews';

import CalendarSection from './calendar';

import styles from './SpecialistFull.scss';

class SpecialistFull extends PureComponent {
  componentDidMount() {
    this.props.dispatch(specialistActions.specialistPracticeChangeActiveTab(this.calculateActiveTab(this.props)));
  }

  componentWillReceiveProps(nextProps) {
    const { specialist, location, dispatch } = this.props;
    if (specialist.id !== nextProps.specialist.id || location.hash !== nextProps.location.hash) {
      dispatch(specialistActions.specialistPracticeChangeActiveTab(this.calculateActiveTab(nextProps)));
    }
  }

  getPracticesButtons = (practices, activePracticeId) =>
    practices.map((practice, i) => (
      <a
        key={`${practice.name}_${practice.id}`}
        href={`#${practice.slug}`}
        className={classNames(
          'button is-rounded',
          {
            'is-primary': practice.id === activePracticeId,
            'is-light': practice.id !== activePracticeId,
          },
          styles.practiceButton
        )}
      >
        <Trans>
          Practice <span className={styles.buttonBadge}>{i + 1}</span>
        </Trans>
      </a>
    ));

  calculateActiveTab = props => {
    const practiceIndex = props.specialist.practices.findIndex(practice => `#${practice.slug}` === props.location.hash);
    return practiceIndex < 0 ? 0 : practiceIndex;
  };

  render() {
    const {
      dispatch,
      specialist,
      allowEdit,
      browser,
      activeTab,
      updateSpecialistProfile: updateAbout,
      location,
    } = this.props;

    const activePractice = specialist.practices[activeTab];
    const activeSpecialistPractice = specialist.specialistPractice[activeTab];
    const practicesButtons = this.getPracticesButtons(specialist.practices, activePractice.id);

    return (
      <div className="main-wrapper">
        <section itemScope itemType="http://schema.org/MedicalClinic" className="container">
          <div className={`row-full ${styles.headerWrapper}`}>
            <SpecialistHeader specialist={specialist} allowEdit={allowEdit} />

            {!browser.greaterThan.mobileBreakpoint &&
              specialist.practices.length > 1 && (
                <div
                  role="button"
                  tabIndex={0}
                  jest="jest-practiceMobileChange"
                  onClick={() =>
                    dispatch(modalActions.showPracticeSelectModal(specialist.practices, activePractice.id))
                  }
                  className={classNames(styles.practiceSelection, 'has-text-centered')}
                >
                  {practicesButtons}
                </div>
              )}
          </div>
          <CalendarSection specialist={specialist} practice={activePractice} />

          <div className="columns">
            <div className="column is-7">
              {browser.greaterThan.mobileBreakpoint &&
                specialist.practices.length > 1 && (
                  <DescriptionCard title={practicesButtons} hasMobileIcon={false}>
                    <div className="is-size-5-5" jest="jest-practiceChange">
                      <ProfileLink
                        className="has-text-weight-semibold"
                        profileType="practice"
                        town={activePractice.town}
                        borough={activePractice.borough}
                        slug={activePractice.slug}
                      >
                        {activePractice.name}
                      </ProfileLink>{' '}
                      - {activePractice.address}
                    </div>
                    <div className="is-size-5-5 has-text-weight-semibold">
                      {activePractice.town} {activePractice.postCode}
                    </div>
                  </DescriptionCard>
                )}

              <DescriptionCard
                isMobile={!browser.greaterThan.mobileBreakpoint}
                titleIconUrl="/images/icons/presentation.svg"
                title={<Trans>Practitioner info</Trans>}
              >
                <About allowEdit={allowEdit} updateAbout={updateAbout} text={specialist.profile} id={specialist.id} />
                <Languages allowEdit={allowEdit} specialist={specialist} town={activePractice.town} />
              </DescriptionCard>

              <DescriptionCard titleIconUrl="/images/icons/map.svg" title={<Trans>Map and access</Trans>}>
                <PracticeLocation practice={activePractice} fullPractice />
              </DescriptionCard>

              {(specialist.phoneNumber || specialist.website) && (
                <DescriptionCard titleIconUrl="/images/icons/clock.svg" title={<Trans>Contacts</Trans>}>
                  <Contacts
                    phoneNumber={specialist.phoneNumber || activePractice.phoneNumber}
                    profileType="specialist"
                    profileTypeId={specialist.id}
                    profileTypeName={`${specialist.firstName} ${specialist.lastName}`}
                    website={specialist.website}
                  />
                </DescriptionCard>
              )}

              {(activeSpecialistPractice.services.length > 0 || allowEdit) && (
                <DescriptionCard titleIconUrl="/images/icons/price.svg" title={<Trans>Price list</Trans>}>
                  <PriceList
                    specialistId={specialist.id}
                    specialistPracticeId={activeSpecialistPractice.id}
                    services={activeSpecialistPractice.services}
                    allowEdit={allowEdit}
                  />
                </DescriptionCard>
              )}

              <DescriptionCard
                id="review_section"
                titleIconUrl="/images/icons/star.svg"
                title={<Trans>Patient reviews: {specialist.reviews.length}</Trans>}
              >
                <Reviews
                  isMobile={!browser.greaterThan.mobileBreakpoint}
                  specialist={specialist}
                  hash={location.hash}
                />
              </DescriptionCard>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

SpecialistFull.propTypes = {
  specialist: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    userId: PropTypes.number,
    profile: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string,
    specialization: PropTypes.string.isRequired,
    practices: PropTypes.array.isRequired,
    languages: PropTypes.array.isRequired,
    specialistPractice: PropTypes.arrayOf(
      PropTypes.shape({
        services: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            price: PropTypes.number,
          })
        ),
      })
    ).isRequired,
    reviews: PropTypes.array.isRequired,
  }),
  allowEdit: PropTypes.bool.isRequired,
  updateSpecialistProfile: PropTypes.func.isRequired,
  browser: PropTypes.shape({
    greaterThan: PropTypes.object,
  }).isRequired,
  activeTab: PropTypes.number,
  location: PropTypes.shape({
    hash: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default SpecialistFull;
